var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    { staticClass: "table" },
    [
      _c("span", { staticClass: "title" }, [_vm._v("试题管理")]),
      _c(
        "el-row",
        { staticClass: "row-bg" },
        [
          _c("el-input", {
            staticClass: "tb-sm-input",
            attrs: { placeholder: "请输入试题id" },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.search.apply(null, arguments)
              },
            },
            model: {
              value: _vm.queryData.inputVal,
              callback: function ($$v) {
                _vm.$set(_vm.queryData, "inputVal", $$v)
              },
              expression: "queryData.inputVal",
            },
          }),
          _c("el-input", {
            staticClass: "tb-sm-input",
            attrs: { placeholder: "请输入题干" },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.search.apply(null, arguments)
              },
            },
            model: {
              value: _vm.queryData.stem,
              callback: function ($$v) {
                _vm.$set(_vm.queryData, "stem", $$v)
              },
              expression: "queryData.stem",
            },
          }),
          _c("el-input", {
            staticClass: "tb-sm-input",
            attrs: { placeholder: "请输入创建人" },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.search.apply(null, arguments)
              },
            },
            model: {
              value: _vm.queryData.creator,
              callback: function ($$v) {
                _vm.$set(_vm.queryData, "creator", $$v)
              },
              expression: "queryData.creator",
            },
          }),
          _c("el-cascader", {
            staticClass: "td-cascader",
            attrs: {
              options: _vm.tagTreeData,
              props: _vm.props,
              placeholder: "请选择所属标签",
              clearable: "",
            },
            model: {
              value: _vm.queryData.tagSet,
              callback: function ($$v) {
                _vm.$set(_vm.queryData, "tagSet", $$v)
              },
              expression: "queryData.tagSet",
            },
          }),
          _c(
            "el-select",
            {
              staticClass: "tb-smselect",
              attrs: { placeholder: "选择类型" },
              model: {
                value: _vm.queryData.seleType,
                callback: function ($$v) {
                  _vm.$set(_vm.queryData, "seleType", $$v)
                },
                expression: "queryData.seleType",
              },
            },
            _vm._l(_vm.typeState, function (item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.value },
              })
            }),
            1
          ),
          _c(
            "el-button",
            {
              staticClass: "tb-button",
              attrs: { type: "primary", size: "medium" },
              on: {
                click: function ($event) {
                  return _vm.search()
                },
              },
            },
            [_vm._v("查 询")]
          ),
          _c(
            "el-button",
            {
              staticClass: "tb-button",
              attrs: { type: "primary", size: "medium" },
              on: {
                click: function ($event) {
                  return _vm.reset()
                },
              },
            },
            [_vm._v("重 置")]
          ),
          _c(
            "el-dropdown",
            { staticClass: "tb-button", attrs: { trigger: "click" } },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "has",
                      rawName: "v-has",
                      value: "testquestion:ManageAdd",
                      expression: "'testquestion:ManageAdd'",
                    },
                  ],
                  attrs: { type: "info", size: "medium" },
                },
                [
                  _vm._v(" 新 增 "),
                  _c("i", { staticClass: "el-icon-arrow-down el-icon--right" }),
                ]
              ),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c(
                    "el-dropdown-item",
                    {
                      nativeOn: {
                        click: function ($event) {
                          return _vm.addTopic(1)
                        },
                      },
                    },
                    [_c("span", [_vm._v("单选题")])]
                  ),
                  _c(
                    "el-dropdown-item",
                    {
                      nativeOn: {
                        click: function ($event) {
                          return _vm.addTopic(2)
                        },
                      },
                    },
                    [_c("span", [_vm._v("多选题")])]
                  ),
                  _c(
                    "el-dropdown-item",
                    {
                      nativeOn: {
                        click: function ($event) {
                          return _vm.addTopic(3)
                        },
                      },
                    },
                    [_c("span", [_vm._v("简答题")])]
                  ),
                  _c(
                    "el-dropdown-item",
                    {
                      nativeOn: {
                        click: function ($event) {
                          return _vm.addTopic(4)
                        },
                      },
                    },
                    [_c("span", [_vm._v("填空题")])]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticClass: "tb-list",
          staticStyle: { width: "100%", "margin-bottom": "20px" },
          attrs: { data: _vm.dataList, border: "" },
        },
        [
          _c("el-table-column", {
            attrs: { label: "id", align: "center", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("uuidFormat")(scope.row.id))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "题干", align: "center", width: "230" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", {
                      staticClass: "title-link",
                      domProps: {
                        textContent: _vm._s(_vm.filterHTMLTag(scope.row.stem)),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.previewSubject(scope.row)
                        },
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "类型", align: "center", width: "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.type))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "标签", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    !scope.row.tagList || scope.row.tagList.length === 0
                      ? [_c("span", [_vm._v("无")])]
                      : _vm._l(scope.row.tagList, function (item) {
                          return _c(
                            "span",
                            { key: item.id },
                            [
                              Boolean(item.groupInfo)
                                ? [
                                    _c("span", {
                                      staticClass: "tag",
                                      style:
                                        "backgroundColor" +
                                        ":" +
                                        item.groupInfo.color,
                                      domProps: {
                                        textContent: _vm._s(item.title),
                                      },
                                    }),
                                  ]
                                : [
                                    _c("span", {
                                      staticClass: "tag",
                                      style: "backgroundColor" + ":" + "#ccc",
                                      domProps: {
                                        textContent: _vm._s(item.title),
                                      },
                                    }),
                                  ],
                            ],
                            2
                          )
                        }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "状态", align: "center", width: "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "span",
                      {
                        style:
                          "color" +
                          ":" +
                          _vm.getStateLessonColor(scope.row.status),
                      },
                      [_vm._v(_vm._s(scope.row.status))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "创建人", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    !scope.row.creatorInfo
                      ? _c("span")
                      : _c("span", [
                          _vm._v(_vm._s(scope.row.creatorInfo.name)),
                        ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "创建时间", align: "center", width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm._f("dateFormat")(scope.row.createTime))
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "操作", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-dropdown",
                      { attrs: { trigger: "click" } },
                      [
                        _c(
                          "el-button",
                          { attrs: { type: "primary", size: "small" } },
                          [
                            _vm._v(" 管 理 "),
                            _c("i", {
                              staticClass: "el-icon-arrow-down el-icon--right",
                            }),
                          ]
                        ),
                        _c(
                          "el-dropdown-menu",
                          { attrs: { slot: "dropdown" }, slot: "dropdown" },
                          [
                            _c(
                              "el-dropdown-item",
                              {
                                directives: [
                                  {
                                    name: "has",
                                    rawName: "v-has",
                                    value: "testquestion:ManageEdit",
                                    expression: "'testquestion:ManageEdit'",
                                  },
                                ],
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.editSubject(scope.row)
                                  },
                                },
                              },
                              [_c("span", [_vm._v("编辑试题")])]
                            ),
                            scope.row.status === "未发布"
                              ? _c(
                                  "el-dropdown-item",
                                  {
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.publishSubject(scope.row)
                                      },
                                    },
                                  },
                                  [_c("span", [_vm._v("发布试题")])]
                                )
                              : _vm._e(),
                            _c(
                              "el-dropdown-item",
                              {
                                directives: [
                                  {
                                    name: "has",
                                    rawName: "v-has",
                                    value: "testquestion:ManageDel",
                                    expression: "'testquestion:ManageDel'",
                                  },
                                ],
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.delSubject(scope.row)
                                  },
                                },
                              },
                              [_c("span", [_vm._v("删除试题")])]
                            ),
                            _c(
                              "el-dropdown-item",
                              {
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.previewSubject(scope.row)
                                  },
                                },
                              },
                              [_c("span", [_vm._v("预览试题")])]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          "current-page": _vm.pagination.currentPage,
          "page-sizes": _vm.pagination.pageSizes,
          "page-size": _vm.pagination.pageSize,
          layout: _vm.pagination.layout,
          total: _vm.pagination.total,
        },
        on: {
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange,
        },
      }),
      _c("single-choicee", {
        attrs: { show: _vm.singleDialog, editTopicData: _vm.editTopicData },
        on: {
          close: function ($event) {
            _vm.singleDialog = false
            _vm.editTopicData = null
          },
          getQuestionInfo: _vm.getQuestionInfo,
        },
      }),
      _c("multiple-choicee", {
        attrs: { show: _vm.multipleDialog, editTopicData: _vm.editTopicData },
        on: {
          close: function ($event) {
            _vm.multipleDialog = false
            _vm.editTopicData = null
          },
          getQuestionInfo: _vm.getQuestionInfo,
        },
      }),
      _c("essay-question", {
        attrs: { show: _vm.questionDialog, editTopicData: _vm.editTopicData },
        on: {
          close: function ($event) {
            _vm.questionDialog = false
            _vm.editTopicData = null
          },
          getQuestionInfo: _vm.getQuestionInfo,
        },
      }),
      _c("filling-question", {
        attrs: { show: _vm.fillingDialog, editTopicData: _vm.editTopicData },
        on: {
          close: function ($event) {
            _vm.fillingDialog = false
            _vm.editTopicData = null
          },
          getQuestionInfo: _vm.getQuestionInfo,
        },
      }),
      _c("preview-topic", {
        attrs: { show: _vm.previewDialog, topicID: _vm.topicID },
        on: {
          close: function ($event) {
            _vm.previewDialog = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }