var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.show,
        "before-close": _vm.close,
        top: "7vh",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        open: _vm.openDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "dia-tit", attrs: { slot: "title" }, slot: "title" },
        [_c("i"), _c("span", [_vm._v("试题预览")])]
      ),
      _c(
        "div",
        { staticClass: "modal-body" },
        [
          _c(
            "el-row",
            [
              _c("el-row", {
                domProps: { innerHTML: _vm._s(_vm.questionInfo.stem) },
              }),
              _c(
                "ul",
                { staticClass: "label-list" },
                _vm._l(_vm.questionInfo.itemList, function (label, index) {
                  return _c(
                    "li",
                    { key: index, staticClass: "label-item clearfix" },
                    [
                      _c("span", {
                        staticClass: "fl mr-10",
                        domProps: {
                          textContent: _vm._s(
                            _vm.optionLetterName(index) + "."
                          ),
                        },
                      }),
                      _c("div", {
                        staticClass: "fl",
                        domProps: { innerHTML: _vm._s(label.content) },
                      }),
                    ]
                  )
                }),
                0
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt-20" },
            [
              _c("span", [_vm._v("正确答案是：")]),
              _vm.questionInfo.type === "多选"
                ? _vm._l(_vm.answerText, function (item, index) {
                    return _c("span", {
                      key: index,
                      staticClass: "answer-color",
                      domProps: {
                        textContent: _vm._s(_vm.optionLetterName(item)),
                      },
                    })
                  })
                : _vm.questionInfo.type === "简答"
                ? [
                    _c("div", {
                      domProps: { innerHTML: _vm._s(_vm.answerText) },
                    }),
                  ]
                : _vm.questionInfo.type === "单选"
                ? [
                    _c("span", {
                      staticClass: "answer-color",
                      domProps: {
                        textContent: _vm._s(
                          _vm.optionLetterName(_vm.answerText)
                        ),
                      },
                    }),
                  ]
                : _vm.questionInfo.type === "填空"
                ? _vm._l(JSON.parse(_vm.answerText), function (item, index) {
                    return _c(
                      "p",
                      { key: index, staticClass: "answer-color" },
                      [_vm._v(" " + _vm._s(item) + " ")]
                    )
                  })
                : _vm._e(),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "mt-20" },
            [
              !_vm.questionInfo.analysis
                ? [_c("div", { staticClass: "analysis" }, [_vm._v("无解析")])]
                : [
                    _c("div", {
                      staticClass: "analysis",
                      domProps: {
                        innerHTML: _vm._s(_vm.questionInfo.analysis),
                      },
                    }),
                  ],
            ],
            2
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }