<template>
  <el-row class="table">
    <span class="title">试题管理</span>
    <el-row class="row-bg">
      <el-input
        class="tb-sm-input"
        placeholder="请输入试题id"
        v-model="queryData.inputVal"
        @keyup.enter.native="search"
      ></el-input>
      <el-input
        class="tb-sm-input"
        placeholder="请输入题干"
        v-model="queryData.stem"
        @keyup.enter.native="search"
      ></el-input>
      <!-- <el-input
        placeholder="请输入试题名/id"
        v-model.trim="queryData.inputVal"
        class="tb-search-input"
        @keyup.enter.native="search"
      >
        <el-select v-model="queryData.select" slot="prepend" placeholder="请选择" style="width: 120px;">
          <el-option label="试题名称" value="1"></el-option>
          <el-option label="试题id" value="2"></el-option>
        </el-select>
        <i slot="prefix" class="el-input__icon el-icon-search"></i>
      </el-input> -->
      <el-input
        class="tb-sm-input"
        placeholder="请输入创建人"
        v-model="queryData.creator"
        @keyup.enter.native="search"
      ></el-input>
       <el-cascader
        class="td-cascader"
        v-model="queryData.tagSet"
        :options="tagTreeData"
        :props="props"
        placeholder="请选择所属标签"
        clearable></el-cascader>
      <el-select v-model="queryData.seleType" placeholder="选择类型" class="tb-smselect">
        <el-option
          v-for="item in typeState"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
      <!-- <el-select v-model="queryData.seleState" placeholder="选择状态" class="tb-smselect">
        <el-option
          v-for="item in queState"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select> -->
      <el-button type="primary" size="medium" @click="search()" class="tb-button">查 询</el-button>
      <el-button type="primary" size="medium" @click="reset()" class="tb-button">重 置</el-button>
      <el-dropdown trigger="click" class="tb-button">
        <el-button type="info" size="medium" v-has="'testquestion:ManageAdd'">
          新 增
          <i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click.native="addTopic(1)">
            <span>单选题</span>
          </el-dropdown-item>
          <el-dropdown-item @click.native="addTopic(2)">
            <span>多选题</span>
          </el-dropdown-item>
          <el-dropdown-item @click.native="addTopic(3)">
            <span>简答题</span>
          </el-dropdown-item>
          <el-dropdown-item @click.native="addTopic(4)">
            <span>填空题</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </el-row>
    <el-table :data="dataList" class="tb-list" style="width: 100%;margin-bottom: 20px;" border>
      <el-table-column label="id" align="center" width="100">
        <template slot-scope="scope">
          <span>{{scope.row.id | uuidFormat}}</span>
        </template>
      </el-table-column>
      <el-table-column label="题干" align="center" width="230">
        <template slot-scope="scope">
          <span class="title-link" @click="previewSubject(scope.row)" v-text="filterHTMLTag(scope.row.stem)"></span>
        </template>
      </el-table-column>
      <el-table-column label="类型" align="center" width="150">
        <template slot-scope="scope">
          <span>{{scope.row.type}}</span>
        </template>
      </el-table-column>
      <el-table-column label="标签" align="center">
        <template slot-scope="scope">
          <template v-if="!scope.row.tagList || scope.row.tagList.length === 0">
            <span>无</span>
          </template>
          <template v-else>
            <span v-for="item of scope.row.tagList" :key="item.id">
              <template v-if="Boolean(item.groupInfo)">
                <span v-text="item.title" class="tag" :style="'backgroundColor'+':'+item.groupInfo.color"></span>
              </template>
              <template v-else>
                <span v-text="item.title" class="tag" :style="'backgroundColor'+':'+ '#ccc'"></span>
              </template>
            </span>
          </template>
        </template>
      </el-table-column>
      <el-table-column label="状态" align="center" width="150">
        <template slot-scope="scope">
          <span :style="'color'+':'+getStateLessonColor(scope.row.status)">{{scope.row.status}}</span>
        </template>
      </el-table-column>
      <el-table-column label="创建人" align="center">
        <template slot-scope="scope">
          <span v-if="!scope.row.creatorInfo"></span>
          <span v-else>{{scope.row.creatorInfo.name}}</span>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" align="center" width="200">
        <template slot-scope="scope">
          <span>{{scope.row.createTime | dateFormat}}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-dropdown trigger="click">
            <el-button type="primary" size="small">
              管 理
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="editSubject(scope.row)" v-has="'testquestion:ManageEdit'">
                <span>编辑试题</span>
              </el-dropdown-item>
              <el-dropdown-item
                @click.native="publishSubject(scope.row)"
                v-if="scope.row.status === '未发布'"
              >
                <span>发布试题</span>
              </el-dropdown-item>
              <el-dropdown-item @click.native="delSubject(scope.row)" v-has="'testquestion:ManageDel'">
                <span>删除试题</span>
              </el-dropdown-item>
              <el-dropdown-item @click.native="previewSubject(scope.row)">
                <span>预览试题</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagination.currentPage"
      :page-sizes="pagination.pageSizes"
      :page-size="pagination.pageSize"
      :layout="pagination.layout"
      :total="pagination.total"
    ></el-pagination>

    <!-- 添加单选题 -->
    <single-choicee
      :show="singleDialog"
      @close="singleDialog=false;editTopicData=null"
      @getQuestionInfo="getQuestionInfo"
      :editTopicData="editTopicData"
    ></single-choicee>

    <!-- 添加多选题 -->
    <multiple-choicee
      :show="multipleDialog"
      @close="multipleDialog=false;editTopicData=null"
      @getQuestionInfo="getQuestionInfo"
      :editTopicData="editTopicData"
    ></multiple-choicee>

    <!-- 添加简答题 -->
    <essay-question
      :show="questionDialog"
      @close="questionDialog=false;editTopicData=null"
      @getQuestionInfo="getQuestionInfo"
      :editTopicData="editTopicData"
    ></essay-question>

    <!-- 添加填空题 -->
    <filling-question
      :show="fillingDialog"
      @close="fillingDialog=false;editTopicData=null"
      @getQuestionInfo="getQuestionInfo"
      :editTopicData="editTopicData"
    ></filling-question>

    <!-- 试题预览 -->
    <preview-topic :show="previewDialog" @close="previewDialog=false" :topicID="topicID"></preview-topic>
  </el-row>
</template>

<script>
import getStateColor from '@/mixins/getStateColor'
import singleChoicee from '@/components/research/testquestion-manage/singleChoice' // 单选
import multipleChoicee from '@/components/research/testquestion-manage/multipleChoice' // 多选
import essayQuestion from '@/components/research/testquestion-manage/essayQuestion' // 简答
import fillingQuestion from '@/components/research/testquestion-manage/fillingQuestion' // 填空
import previewTopic from '@/components/research/testquestion-manage/previewTopic'
import { getQuestionInfo, editQuestion, delQuestion } from '@/api/research/testquestion'
import getTag from '@/mixins/getTag'

export default {
  components: {
    singleChoicee,
    multipleChoicee,
    essayQuestion,
    fillingQuestion,
    previewTopic
  },
  mixins: [getStateColor, getTag],
  data () {
    return {
      queryData: {
        inputVal: '',
        select: '1',
        creator: '',
        seleType: '',
        stem: '',
        // seleState: '',
        tagSet: []
      },
      props: {
        multiple: true,
        value: 'id',
        label: 'title'
      },
      subjectDialog: false,
      singleDialog: false,
      multipleDialog: false,
      questionDialog: false,
      fillingDialog: false,
      previewDialog: false,
      topicID: '',
      dataList: [],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0
      },
      editTopicData: null,
      queState: [
        {
          value: '已发布',
          label: '已发布'
        },
        {
          value: '未发布',
          label: '未发布'
        }
      ],
      typeState: [
        {
          value: '单选',
          label: '单选'
        },
        {
          value: '多选',
          label: '多选'
        },
        {
          value: '简答',
          label: '简答'
        },
        {
          value: '填空',
          label: '填空'
        }
      ]
    }
  },
  created () {
    this.getQuestionInfo()
  },
  methods: {
    // 获取试题详细信息
    async getQuestionInfo () {
      if (this.queryData.tagSet.length === 0) {
        const res = await getQuestionInfo({
          pageNum: this.pagination.currentPage,
          pageSize: this.pagination.pageSize,
          stem: this.queryData.stem,
          idKey: this.queryData.inputVal,
          creatorName: this.queryData.creator,
          type: this.queryData.seleType,
          statusSet: ["已发布"]
        })
        this.dataList = res.body.list
        this.pagination.total = res.body.total
      } else {
        const tagSet = []
        const tagSetArr = this.queryData.tagSet
        tagSetArr.forEach((ele, idx) => {
          tagSet.push(
            ele[ele.length-1]
          )
        })

        const res = await getQuestionInfo({
          pageNum: this.pagination.currentPage,
          pageSize: this.pagination.pageSize,
          title: this.queryData.select === '1' ? this.queryData.inputVal : '',
          idKey: this.queryData.select === '2' ? this.queryData.inputVal : '',
          creatorName: this.queryData.creator,
          type: this.queryData.seleType,
          statusSet: ["已发布"],
          tagSet: tagSet
        })
        this.dataList = res.body.list
        this.pagination.total = res.body.total
      }

    },

    // 新增试题
    addTopic (type) {
      if (type === 1) {
        this.singleDialog = true
      } else if (type === 2) {
        this.multipleDialog = true
      } else if (type === 3) {
        this.questionDialog = true
      } else {
        this.fillingDialog = true
      }
    },

    // 编辑试题
    editSubject (row) {
      this.editTopicData = row
      if (row.type === '单选') {
        this.singleDialog = true
      } else if (row.type === '多选') {
        this.multipleDialog = true
      } else if (row.type === '简答') {
        this.questionDialog = true
      } else {
        this.fillingDialog = true
      }
    },

    // 发布试题
    publishSubject (row) {
      editQuestion({
        id: row.id,
        status: '已发布'
      }).then(res => {
        if (res.state === 'success') {
          window.$msg('发布成功')
          this.getQuestionInfo()
        }
      })
    },

    // 关闭试题
    closeSubject (row) {
      editQuestion({
        id: row.id,
        status: '未发布'
      }).then(res => {
        if (res.state === 'success') {
          window.$msg('关闭成功')
          this.getQuestionInfo()
        }
      })
    },

    filterHTMLTag (msg) {
      var msg = msg.replace(/<\/?[^>]*>/g, '') //去除HTML Tag
      msg = msg.replace(/[|]*\n/, '') //去除行尾空格
      msg = msg.replace(/&nbsp;/ig, '') //去掉nbsp
      return msg.substring(0, 20)
    },

    // 预览试题
    previewSubject (row) {
      this.topicID = row.id
      this.previewDialog = true
    },

    // 更新视图
    updateView () {
      this.getQuestionInfo()
    },

    // 分页
    handleCurrentChange (val) {
      this.pagination.currentPage = val
      this.getQuestionInfo()
    },
    handleSizeChange (val) {
      this.pagination.pageSize = val
      this.getQuestionInfo()
    },

    // 搜索
    search () {
      this.pagination.currentPage = 1
      this.getQuestionInfo()
    },

    // 重置
    reset () {
      this.queryData = {
        inputVal: '',
        select: '1',
        creator: '',
        seleType: '',
        stem: '',
        seleState: '',
        tagSet: []
      }
    },

    // 删除
    delSubject (row) {
      this.$confirm('确定删除当前试题吗？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          await delQuestion({
            ids: [row.id]
          })
          window.$msg('删除成功')
          this.getQuestionInfo()
        })
        .catch(() => {
          return false
        })
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../style/table.scss";

.tag {
  display: inline-block;
  color: #fff;
  border-radius: 17px 20px 0px 17px;
  padding: 3px 15px;
  margin-right: 10px;
  margin-bottom: 8px;
}
</style>
