<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    @open="openDialog"
    top="7vh"
    :close-on-click-modal="false"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span>试题预览</span>
    </div>

    <div class="modal-body">
      <el-row>
        <el-row v-html="questionInfo.stem"></el-row>

        <ul class="label-list">
          <li
            class="label-item clearfix"
            v-for="(label, index) in questionInfo.itemList"
            :key="index"
          >
            <span class="fl mr-10" v-text="optionLetterName(index) + '.'"></span>
            <div class="fl" v-html="label.content"></div>
            <!-- <span v-html="label.content.replace(/<[/]?p>/, '')"></span> -->
          </li>
        </ul>
      </el-row>

      <!-- 正确答案 -->
      <div class="mt-20">
        <span>正确答案是：</span>
        <template v-if="questionInfo.type === '多选'">
          <span
            v-for="(item, index) of answerText"
            :key="index"
            v-text="optionLetterName(item)"
            class="answer-color"
          ></span>
        </template>
        <template v-else-if="questionInfo.type === '简答'">
          <div v-html="answerText"></div>
        </template>
        <template v-else-if="questionInfo.type === '单选'">
          <span v-text="optionLetterName(answerText)" class="answer-color"></span>
        </template>
        <template v-else-if="questionInfo.type === '填空'">
          <p v-for="(item, index) of JSON.parse(answerText)" :key="index" class="answer-color">
            {{item}}
          </p>
          <!-- <span>填空题答案：{{answerText}}</span> -->
        </template>
      </div>
      <!-- 解析 -->
      <div class="mt-20">
        <template v-if="!questionInfo.analysis">
          <div class="analysis">无解析</div>
        </template>
        <template v-else>
          <div v-html="questionInfo.analysis" class="analysis"></div>
        </template>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import getOptionName from '@/mixins/getOptionName'
import { questionGetInfo } from '@/api/research/testquestion'
import Prism from "prismjs"; //引入插件

export default {
  mixins: [getOptionName],
  props: {
    show: {
      default: false,
      type: Boolean
    },
    topicID: {
      default: '',
      type: String
    }
  },
  data () {
    return {
      questionInfo: {},
      answerText: null, // 正确答案
      radio: '',
      check: []
    }
  },
  methods: {
    openDialog () {
      this.questionInfo = {}
      this.answerText = null
      this.radio = ''
      this.check = []
      this.getQuestionInfo()
    },

    // 获取试题详细信息
    async getQuestionInfo () {
      const res = await questionGetInfo({
        id: this.topicID
      })
      this.questionInfo = { ...res.body }
      if (res.body.type === '单选') {
        let correctAnswer = JSON.parse(res.body.correctAnswer)[0]
        res.body.itemList.forEach((item, index) => {
          if (item.id === correctAnswer) {
            this.answerText = index
          }
        })
      } else if (res.body.type === '多选') {
        this.answerText = []
        let correctAnswer = JSON.parse(res.body.correctAnswer)
        res.body.itemList.forEach((item, index) => {
          correctAnswer.forEach((ele, idx) => {
            if (item.id === ele) {
              this.answerText.push(index)
            }
          })
        })
      } else if (res.body.type === '简答') {
        this.answerText = res.body.correctAnswer
      } else {
        this.answerText = res.body.correctAnswer
      }
      this.$nextTick(() => {
        Prism.highlightAll()
      })
    },

    close () {
      this.$emit('close')
    },

    optionChange () {
      this.$forceUpdate()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../style/dialog.scss";
.modal-body {
  padding-left: 20px;
  font-size: 16px;
}

.label-list {
  margin-top: 15px;
  .label-item {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

/deep/ .el-radio-group {
  margin-right: 20px;
}

/deep/ .el-checkbox-group {
  display: inline-block;
  margin-right: 20px;
}

.analysis {
  background-color: #fafafa;
  border: 1px solid #f5f5f5;
  padding: 10px 0;
  padding-left: 20px;
}

.answer-color {
  color: #f5c319;
}
</style>
